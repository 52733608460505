export const reviewsQuery = gql`
    query customerReviews($first: Int, $page: Int) {
        customerReviews(first: $first, page: $page) {
            data {
                id
                firstName
                createdAt
                updatedAt
                rating
                comment
            }
            paginatorInfo {
                count
                currentPage
                firstItem
                hasMorePages
                lastItem
                lastPage
                perPage
                total
            }
        }
        getCustomerReviewStatistics {
            hasReviewsEnabled
            averageRating
            totalReviews
            ratingPercentages {
                count
                percentage
                rating
            }
        }
    }
`
