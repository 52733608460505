import { reviewsQuery } from '~/graphql/queries/reviews.query.gql'
import { apiCall } from '~/composables/apiCall'
import type { customerReviews } from '~/types/local-types'

export const reviews = defineStore('reviews', {
    state: (): customerReviews => <customerReviews>({
        allReviews: {},
        reviewStats: {}
    } as customerReviews),
    getters: {
        hasReviews: state => {
            return state.reviewStats?.hasReviewsEnabled && (state.allReviews?.data && Object.keys(state.allReviews?.data).length > 0)
        }
    },
    actions: {
        async fetchReviewStats () {
            if (Object.keys(this.reviewStats).length === 0) {
                this.reviewStats = await apiCall(reviewsQuery, 'getCustomerReviewStatistics', {}, false)
            }
        },
        async fetchCustomerReviews (amount: number, page: number) {
            this.allReviews = await apiCall(reviewsQuery, 'customerReviews', { first: amount, page }, false)
        }
    }
})

if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(reviews, import.meta.hot))
}
