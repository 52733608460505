<template>
    <div class="flex flex-row flex-wrap items-center gap-2">
        <Icon v-for="index in fullStars" :key="index" :icon="IconStar" class="fill-star-filled text-star-filled" />
        <!-- eslint-disable-next-line tailwindcss/no-custom-classname -->
        <Icon v-if="halfStar" :icon="IconStar" class="half-star fill-star-empty text-star-empty" />
        <Icon v-for="index in emptyStars" :key="index" :icon="IconStar" class="fill-star-empty text-star-empty" />
    </div>
</template>

<script setup lang="ts">
    import type { CustomerReviewStatisticsOrderBuddy } from '~/types/orderbuddy-types'
    import Icon from '~/components/Icon.vue'
    import IconStar from '~/assets/icons/star.svg'
    interface StarRating extends CustomerReviewStatisticsOrderBuddy {
        rating: number
    }
    const props = withDefaults(defineProps<StarRating>(), {
        rating: 0
    })
    const maxStars = 5
    const fullStars = Math.floor(Number(props.rating))
    const halfStar = Number(props.rating) % 1 >= 0.5
    const emptyStars = maxStars - fullStars - (halfStar ? 1 : 0)
</script>

<style lang="scss">
    .half-star {
        path {
            &:first-child {
                @apply fill-star-filled text-star-filled;
            }
        }
    }
</style>
